export default {

  levels: (state) => {
    return state.levels;
  },
  levelOptions: (state) => {
    const levelOptions = [];
    state.levels.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
        icon: item.iconPath,
      };
      levelOptions.push(pushItem);
    }
    return levelOptions;
  },

  labels: (state) => {
    return state.labels;
  },
  labelOptions: (state) => {
    const labelOptions = [];
    state.labels.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
        icon: item.iconPath,
      };
      labelOptions.push(pushItem);
    }
    return labelOptions;
  },

  provinces: (state) => {
    return state.provinces;
  },
  provinceOptions: (state) => {
    const provinceOptions = [];
    state.provinces.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      provinceOptions.push(pushItem);
    }
    return provinceOptions;
  },

  cities: (state) => {
    return state.cities;
  },
  citiesOptions: (state) => {
    const citiesOptions = [];
    state.cities.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      citiesOptions.push(pushItem);
    }
    return citiesOptions;
  },

  banks: (state) => {
    return state.banks;
  },
  bankOptions: (state) => {
    const bankOptions = [];
    state.banks.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      bankOptions.push(pushItem);
    }
    return bankOptions;
  },

  vendorCategory: (state) => {
    return state.vendorCategory;
  },
  vendorCategoryOptions: (state) => {
    const vendorCategoryOptions = [];
    state.vendorCategory.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      vendorCategoryOptions.push(pushItem);
    }
    return vendorCategoryOptions;
  },

  vendors: (state) => {
    return state.vendors;
  },
  vendorCatIdOptions: (state) => {
    const vendorCatIdOptions = [];
    state.vendors.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      vendorCatIdOptions.push(pushItem);
    }
    return vendorCatIdOptions;
  },

  customerOptions: (state) => {
    const customerOptions = [];
    state.customers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.fullName + ' (' + item.mobile + ')',
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },


  // Static Getters ---------------------------------------------------------------

  isMarreidOptions() {
    return [
      { value: "1", text: "مجرد" },
      { value: "2", text: "متاهل" },
    ];
  },

  requestTypeOptions() {
    return [
      { value: 1, text: "قفسه جایزه" },
      { value: 2, text: "ارسال پیام" },
    ];
  },

  requestForOptions() {
    return [
      { value: 1, text: "مجموعه‌های داخل مرکز" },
      { value: 2, text: "مجموعه‌های خارج از مرکز" },
    ];
  },

 scoreTypeOptions() {
    return [
      { value: 1, text: "امتیازدهی ثابت" },
      { value: 2, text: "امتیازدهی متغیر" },
    ];
  },

 daysOptions() {
    return [
      { value: 1, text: "شنبه" },
      { value: 2, text: "یکشنبه" },
      { value: 3, text: "دوشنبه" },
      { value: 4, text: "سه‌شنبه" },
      { value: 5, text: "چهارشنبه" },
      { value: 6, text: "پنج‌شنبه" },
      { value: 7, text: "جمعه" },
    ];
  },

 typeOptions() {
    return [
      { value: 1, text: "روز هفته" },
      { value: 2, text: "ماه" },
    ];
  },

};

export default {

  setUsers(state, data) {
    state.users = data;
  },

  setUser(state, data) {
    state.user = data;
  },

  setLevels(state, data) {
    state.levels = data;
  },

  setLabels(state, data) {
    state.labels = data;
  },

};

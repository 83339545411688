import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";

// Admin vuex Modules
import baseX from "./modules/baseX";
import operationX from "./modules/operationX";
import dropDownX from "./modules/dropDownX";
import discountX from "./modules/discountX";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    baseX,
    operationX,
    dropDownX,
    discountX,
  },
});

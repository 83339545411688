export default {

    vendorDetails: {},
    vendoreBranchs: [],
    vendoreBranch: {},
    parts: [],
    vendoreBranchCustomers: [],

    vendorsLevel: [],
    vendorsLabel: [],

    customers: [],
    customer: {},
    customerLevel: {},
    customerLabel: {},
    cards: [],
    customerCheck: null,

    smartData: [],
    smartDataId: {},

    populationCenters: [],
    populationId: {},

    requests: [],

    prizeShelf: [],

    vendorScore: [],
    vendorScoreDetails: {},

    sentRequest: [],

    receivedRequest: [],
    receivedReqDetail: {},
    vendorList: [],

    polls: [],
    poll: {},

    transactions: [],
    transaction: {},

    dashboard: {},
};

<template>
  <v-menu
    bottom
    rounded
    offset-y
    nudge-bottom="6"
    transition="scale-transition"
    right
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        x-large
        v-on="on"
        class="ml-12"
      >
          <v-avatar
            color="blue"
            size="40"
          >
            <img
              :src="profile.avatarPath"
              alt="profile Avatar"
            >
          </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center py-1">
        <div class="text-center">
          <h5 class="px-0">
            {{ profile.name }}
          </h5>
          <p class="p-fs-s mt-1">
            ( {{ profile.roleTitle }} )
          </p>
          <v-divider class="my-1"></v-divider>
          <v-btn
            text
            small
            block
            class="account-dd-nav-link"
            :to="{name: 'Profile'}"
          >
            {{ label.menu.editProfile }}
          </v-btn>
          <v-divider class="my-1"></v-divider>
          <v-btn
            text
            small
            block
            class="account-dd-nav-link"
            color="error"
            @click="logout"
          >
          <v-icon small class="mr-1">mdi-power</v-icon>
            {{ label.menu.logout }}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import label from "@/lang/StaticLabels.js";

export default {
  name: "TheAccountDropDown",
  data() {
    return{
      label,
    }
  },
  computed: {
    ...mapGetters({
      profile: "userProfile",
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

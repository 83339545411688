import Vue from 'vue'
import VueRouter from 'vue-router'
import GlobalGuards from "@/guards/Global";
import label from "@/lang/StaticLabels.js";

import TheContainer from "@/containers/TheContainer";
const Page404 = () => import("@/views/errors/Page404");
const Page403 = () => import("@/views/errors/Page403");
const Page500 = () => import("@/views/errors/Page500");
const PageUnderConstruction = () => import("@/views/errors/PageUnderConstruction");

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Container",
    component: TheContainer,
    redirect: "/dashboard",
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          label: label.page.dashboard,
          requiresAuth: true,
        },
        component: () => import('@/views/pages/Dashboard'),
      },
      {
        path: 'profile',
        name: 'Profile',
        meta: {
          label: label.page.profile,
          requiresAuth: true,
        },
        component: () => import('@/views/auth/Profile'),
      },

      {
        path: 'store-manager',
        name: 'StoreManager',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/store-manager/add',
        children: [
          {
            path: 'add',
            name: 'StoreManagerAdd',
            component: () => import('@/views/pages/storeManager/Add'),
            meta: {
              label: label.menu.manageShop,
              requiresAuth: true
            }
          },
          {
            path: 'customers/:id',
            name: 'StoreManagerList',
            component: () => import('@/views/pages/storeManager/List'),
            meta: {
              label: "لیست مشتریان شعبه",
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'vino',
        name: 'SmartData',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/list',
        children: [
          {
            path: 'smartData/list',
            name: 'SmartDataList',
            component: () => import('@/views/pages/smartData/List'),
            meta: {
              label: label.page.smartData,
              requiresAuth: true
            }
          },
          {
            path: 'request/add/:key/:id',
            name: 'AddRequest',
            component: () => import('@/views/pages/smartData/AddRequest'),
            meta: {
              label: '',
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'discount',
        name: 'Discount',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/discount/list',
        children: [
          {
            path: 'list',
            name: 'DiscountList',
            component: () => import('@/views/pages/discount/List'),
            meta: {
              label: label.page.incentiveDiscountPlans,
              requiresAuth: true
            }
          },
          {
            path: 'add/point-plan',
            name: 'AddPointsPlan',
            component: () => import('@/views/pages/discount/add/Point'),
            meta: {
              label: label.page.point,
              requiresAuth: true
            }
          },
          {
            path: 'add/forget-plan',
            name: 'AddForgetPaln',
            component: () => import('@/views/pages/discount/add/Forget'),
            meta: {
              label: label.page.forget,
              requiresAuth: true
            }
          },
          {
            path: 'add/discount-plan',
            name: 'AddDiscountPlan',
            component: () => import('@/views/pages/discount/add/Discount'),
            meta: {
              label: label.page.discount,
              requiresAuth: true
            }
          },
          {
            path: 'add/public-plan',
            name: 'AddPublicPlan',
            component: () => import('@/views/pages/discount/add/Public'),
            meta: {
              label: label.page.general,
              requiresAuth: true
            }
          },
          {
            path: 'add/sale-plan',
            name: 'AddSalePlan',
            component: () => import('@/views/pages/discount/add/Sale'),
            meta: {
              label: label.page.sale,
              requiresAuth: true
            }
          },
          {
            path: 'add/event-plan',
            name: 'AddEventPaln',
            component: () => import('@/views/pages/discount/add/Event'),
            meta: {
              label: label.page.event,
              requiresAuth: true
            }
          },
          {
            path: 'add/credit-plan',
            name: 'AddCreditPlan',
            component: () => import('@/views/pages/discount/add/Credit'),
            meta: {
              label: label.page.credit,
              requiresAuth: true
            }
          },
          {
            path: 'add/loyality-plan',
            name: 'AddLoyalityPlan',
            component: () => import('@/views/pages/discount/add/Loyality'),
            meta: {
              label: label.page.loyality,
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'customer-club',
        name: 'customer-club',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/customer-club/share/requests',
        children: [
          {
            path: 'requests',
            name: 'CustomerClub',
            component: () => import('@/views/pages/customerClub/share/Requests'),
            meta: {
              label: label.page.shareCustomerClub,
              requiresAuth: true
            }
          },
          {
            path: 'business-List',
            name: 'BussinessList',
            component: () => import('@/views/pages/customerClub/share/BusinessList'),
            meta: {
              label: label.page.businessList,
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'customer',
        name: 'Customer',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/customer/List',
        children: [
          {
            path: 'list',
            name: 'CustomerList',
            component: () => import('@/views/pages/customer/List'),
            meta: {
              label: label.menu.manageCustomer,
              requiresAuth: true
            }
          },
          {
            path: 'add',
            name: 'AddCustomer',
            component: () => import('@/views/pages/customer/Add'),
            meta: {
              label: label.menu.addCustomer,
              requiresAuth: true
            }
          },
          {
            path: "detail/:id",
            name: 'CustomerDetail',
            component: () => import('@/views/pages/customer/Detail'),
            meta: {
              label: label.menu.detailCustomer,
              requiresAuth: true
            }
          },
          {
            path: 'categories',
            name: 'customerCategories',
            component: () => import('@/views/pages/customer/Categories'),
            meta: {
              label: label.menu.customerCategories,
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'Vote',
        name: 'Vote',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/vote/list',
        children: [
          {
            path: 'list',
            name: 'VoteList',
            component: () => import('@/views/pages/vote/List'),
            meta: {
              label: label.menu.vote,
              requiresAuth: true
            }
          },
          {
            path: 'add/:id?',
            name: 'VoteAdd',
            component: () => import('@/views/pages/vote/Add'),
            meta: {
              label: "افزودن نظرسنجی جدید",
              requiresAuth: true
            }
          },
          {
            path: 'detail/:id',
            name: 'VoteDetail',
            component: () => import('@/views/pages/vote/Detail'),
            meta: {
              label: label.menu.detailVote,
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'population-centers',
        name: 'populationCenters',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/population-centers/list',
        children: [
          {
            path: 'list',
            name: 'populationCentersList',
            component: () => import('@/views/pages/populationCenters/List'),
            meta: {
              label: label.menu.populationCenters,
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'request',
        name: 'Requests',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/request/list',
        children: [
          {
            path: 'list',
            name: 'RequestList',
            component: () => import('@/views/pages/request/List'),
            meta: {
              label: "درخواست‌های ارسال پیام",
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'manage-gift',
        name: 'ManageGift',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/manage-gift/gift',
        children: [
          {
            path: 'gift',
            name: 'manageGiftList',
            component: () => import('@/views/pages/manageGift/Gift'),
            meta: {
              label: label.menu.manageGift,
              requiresAuth: true
            }
          },
          {
            path: 'customers/:id',
            name: 'UserList',
            component: () => import('@/views/pages/manageGift/UserList'),
            meta: {
              label: label.menu.giftUserList,
              requiresAuth: true
            }
          },
        ]
      },

      {
        path: 'prizeShelf',
        name: 'PrizeShelf',
        meta: {
          label: label.menu.prizeShelf,
          requiresAuth: true,
        },
        component: () => import('@/views/pages/prizeShelf/PrizeShelf'),
      },
      {
        path: 'support',
        name: 'Support',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/support/list',
        children: [
          {
            path: 'list',
            name: 'SupportList',
            component: () => import('@/views/pages/support/index'),
            meta: {
              label: label.page.tickets,
              requiresAuth: true
            }
          },
          {
            path: 'add',
            name: 'AddSupport',
            component: () => import('@/views/pages/support/Add'),
            meta: {
              label: label.page.addTicket,
              requiresAuth: true
            }
          },
        ]
      },

      {
        path: 'transactions',
        name: 'Transactions',
        meta: {
          label: 'تراکنش‌ و پرداخت‌های مشتریان',
          requiresAuth: true,
        },
        component: () => import('@/views/pages/transactions/List'),
      },

    ],
  },


  // other Pages - without container
  {
    path: "/login",
    name: "Login",
    meta: {
      label: label.page.login,
    },
    component: () => import("@/views/auth/Login"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },
  {
    path: "/verification-code",
    name: "VerificationCode",
    meta: {
      label: label.page.login,
    },
    component: () => import("@/views/auth/Verification"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      label: label.page.register,
    },
    component: () => import("@/views/auth/Register"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },

  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: Page404,
      },
      {
        path: "403",
        name: "Page403",
        component: Page403,
      },
      {
        path: "500",
        name: "Page500",
        component: Page500,
      },
      {
        path: "underConstruction",
        name: "PageUnderConstruction",
        component: PageUnderConstruction,
      },
    ],
  },

  {
    path: "*",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => GlobalGuards.beforeEach(to, from, next));
router.afterEach((to, from) => GlobalGuards.afterEach(to, from));

export default router

import label from "@/lang/StaticLabels.js";

export default {
  token: null,
  checkCode: false,
  loginCheck: false,
  forgotCheck: false,
  forgotBtn: false,
  userProfile: {},

  postCheck: false,
  checkAlert: 0,
  successMessage: null,
  warningMessage: null,
  infoMessage: null,
  dangerMessage: null,

  loadingCheck: 0,
  loadingMessage: label.caption.loading,
  loadingBtn: false,

};

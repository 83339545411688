import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  // Users Actions ------------------------------------------------------------------
  getUsers({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("users")
      .then((res) => {
        commit("setUsers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getUser({ dispatch, commit }, data) {
    axios
      .get("users/" + data)
      .then((res) => {
        commit("setUser", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addUser({ dispatch }, data) {
    axios
      .post("users", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.addUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editUser({ dispatch }, data) {
    axios
      .put("users/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.editUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  changeUserStatus({ dispatch }, data) {
    axios
      .put("users/status/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  resetUserPassword({ dispatch }, data) {
    axios
      .put("users/resetPassword/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.resetPassword,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteUser({ dispatch }, data) {
    axios
      .delete("users/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.deleteUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Levels Actions ------------------------------------------------------------------
  getLevels({ dispatch, commit }) {
    axios
      .get("admin/level")
      .then((res) => {
        commit("setLevels", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addLevel({ dispatch }, data) {
    axios
      .post("admin/level", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editLevel({ dispatch }, data) {
    axios
      .put("admin/level/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteLevel({ dispatch }, data) {
    axios
      .delete("admin/level/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Lebels Actions ------------------------------------------------------------------
  getLabels({ dispatch, commit }) {
    axios
      .get("admin/label")
      .then((res) => {
        commit("setLabels", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addLabel({ dispatch }, data) {
    axios
      .post("admin/lebel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editLabel({ dispatch }, data) {
    axios
      .put("admin/lebel/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteLabels({ dispatch }, data) {
    axios
      .delete("admin/lebel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

};

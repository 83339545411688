export default {

  levels: [],

  labels: [],

  provinces: [],
  cities: [],

  banks: [],

  vendorCategory: [],
  vendors: [],

  customers: [],

};

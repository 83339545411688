export default {

  setLevels(state, data) {
    state.levels = data;
  },

  setLabels(state, data) {
    state.labels = data;
  },

  setProvinces(state, data) {
    state.provinces = data;
  },

  setCities(state, data) {
    state.cities = data;
  },

  setBanks(state, data) {
    state.banks = data;
  },

  setVendorCategory(state, data) {
    state.vendorCategory = data;
  },

  setVendors(state, data) {
    state.vendors = data;
  },

  setCustomers(state, data) {
    state.customers = data;
  },

};

export default {

  users: [],
  user: {},

  levels: [],

  labels: [],

};

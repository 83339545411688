import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  getVendorDetails({ dispatch, commit }) {
    axios
      .get("vendor")
      .then((res) => {
        commit("setVendorDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  changeVendorLogo({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendor/logo", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendorDetails({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("vendor/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  // Branchs Actions ------------------------------------------------------------------
  getVendoreBranchs({ dispatch, commit }) {
    axios
      .get("vendorBranch")
      .then((res) => {
        commit("setVendoreBranchs", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  getParts({ dispatch, commit }, data) {
    axios
      .get("vendorBranch/parts/" + data)
      .then((res) => {
        commit("setParts", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getVendoreBranch({ dispatch, commit }, data) {
    axios
      .get("vendorBranch/" + data)
      .then((res) => {
        commit("setVendoreBranch", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendoreBranch({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendoreBranch({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("vendorBranch/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendoreBranch({ dispatch }, data) {
    axios
      .delete("vendorBranch/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addVendoreBranchPart({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch/part", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendoreBranchPart({ dispatch }, data) {
    axios
      .delete("vendorBranch/part/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandlerSimple",
          {
            server: res,
            message: alert.success.delete
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getVendoreBranchCustomers({ dispatch, commit }, data) {
    axios
      .get("vendorBranch/customers/" + data)
      .then((res) => {
        commit("setVendoreBranchCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendoreBranchCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch/customers", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendoreBranchCustomer({ dispatch }, data) {
    axios
      .delete("vendorBranch/customers?customerId=" + data.customer +
        "&branchPartId=" + data.part)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addVendoreBranchManager({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch/user", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },


  // Vendor Levels Actions ------------------------------------------------------------------
  getVendorsLevel({ dispatch, commit }) {
    axios
      .get("vendorLevel")
      .then((res) => {
        commit("setVendorsLevel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendorLevel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorLevel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendorLevel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("vendorLevel/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendorLevel({ dispatch }, data) {
    axios
      .delete("vendorLevel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  //Vendor Lebels Actions ------------------------------------------------------------------
  getVendorsLabel({ dispatch, commit }) {
    axios
      .get("vendorLabel")
      .then((res) => {
        commit("setVendorsLabel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendorLabel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorLabel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendorLabel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("vendorLebel/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendorLabel({ dispatch }, data) {
    axios
      .delete("vendorLabel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //Customers Actions ------------------------------------------------------------------
  getCustomers({ dispatch, commit }, data) {
    axios
      .get("business/customer?branchPartId=" + data)
      .then((res) => {
        commit("setCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getCustomer({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/customer/" + data)
      .then((res) => {
        commit("setCustomer", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/customer", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: "CustomerList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  addCustomersWithExcel({ commit, dispatch }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
    .post("business/customer/import", data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
      dispatch('successPOSTHandler',
      {server: res,
       message: alert.success.addCustomers,
       redirect: false},
       {root: true})
    })
    .catch(error => {
      dispatch('errorPOSTHandler', error, {root:true});
    })
    .finally(() => {
      setTimeout(() => {
        commit("setLoadingBtn", false, { root: true });
      }, 1000);
    });
  },

  exportCustomersExcel({ dispatch }) {
    axios
    .get("business/customer/export", {
    responseType: 'blob'
    })
    .then(res => {
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'customers.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch(error => {
      dispatch('errorPOSTHandler', error, {root:true});
    });
  },

  editCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/customer/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteCustomer({ dispatch }, data) {
    axios
      .delete("business/customer/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  checkCustomerMobile({ dispatch, commit }, data) {
    axios
      .get("business/customer/check?mobile=" + data.mobile +
            '&branchPartId=' + data.branchPart)
      .then((res) => {
        commit("setCustomerCheck", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

// Customer Level and Label Actions ------------------------------------------------------------------
  getCustomerLevel({ dispatch, commit }, data) {
    axios
      .get("business/customerLevel/" + data)
      .then((res) => {
        commit("setCustomerLevel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  updateCustomerLevel({ dispatch }, data) {
    axios
      .post("business/customerLevel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getCustomerLabel({ dispatch, commit }, data) {
    axios
      .get("business/customerLabel/" + data)
      .then((res) => {
        commit("setCustomerLabel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  updateCustomerLabel({ dispatch }, data) {
    axios
      .post("business/customerLabel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //Cards Actions ------------------------------------------------------------------
  getBankCards({ dispatch, commit }, data) {
    axios
      .get("business/customerCard/" + data)
      .then((res) => {
        commit("setCards", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addBankCard({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/customerCard", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editBankCard({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/customerCard/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteBankCard({ dispatch }, data) {
    axios
      .delete("business/customerCard/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //SmartData Actions ------------------------------------------------------------------
  getSmartData({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/smartdata" + data)
      .then((res) => {
        commit("setSmartData", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  getSmartDataById({ dispatch, commit }, data) {
    axios
      .get("business/smartdata/" + data)
      .then((res) => {
        commit("setSmartDataId", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  //Population Actions ------------------------------------------------------------------
  getPopulationCenters({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/population" + data)
      .then((res) => {
        commit("setPopulationCenters", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  getPopulationById({ dispatch, commit }, data) {
    axios
      .get("business/population/" + data)
      .then((res) => {
        commit("setPopulationId", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  //Message Request Actions ------------------------------------------------------------------
  getMessageRequest({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/messageRequest")
      .then((res) => {
        commit("setRequests", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  addRequest({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/messageRequest", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: "RequestList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  deleteRequest({ dispatch }, data) {
    axios
      .delete("business/messageRequest/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  //PrizeShelf Actions ------------------------------------------------------------------
  getPrizeShelf({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/prizeShelf?branchPartId=" + data)
      .then((res) => {
        commit("setPrizeShelf", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  addPrizeShelf({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/prizeShelf", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  editPrizeShelf({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/prizeShelf/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  deletePrizeShelf({ dispatch }, data) {
    axios
      .delete("business/prizeShelf/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  //VendorScore Actions ------------------------------------------------------------------
  getVendorScore({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/vendorScore?branchPartId=" + data)
      .then((res) => {
        commit("setVendorScore", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getVendorScoreDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/vendorScore/" + data)
      .then((res) => {
        commit("setVendorScoreDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addVendorScore({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/vendorScore", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  editVendorScore({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/vendorScore/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  deleteVendorScore({ dispatch }, data) {
    axios
      .delete("business/vendorScore/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //ClubSharing Actions ------------------------------------------------------------------
  getSentRequest({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/clubSharing?branchPartId=" + data)
      .then((res) => {
        commit("setSentRequest", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getReceivedRequest({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/clubSharing/received?branchPartId=" + data)
      .then((res) => {
        commit("setReceivedRequest", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getReceivedReqId({ dispatch, commit }, data) {
    axios
      .get("business/clubSharing/" + data)
      .then((res) => {
        commit("setReceivedReqDetail", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addRequestClub({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/clubSharing", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  changeClubSharingStatus({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/clubSharing/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteClubSharing({ dispatch }, data) {
    axios
      .delete("business/clubSharing/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getVendorList({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/clubSharing/vendors")
      .then((res) => {
        commit("setVendorsList", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  //Poll Actions ------------------------------------------------------------------
  getPolls({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/poll?branchPartId=" + data)
      .then((res) => {
        commit("setPolls", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getPollDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/poll/" + data)
      .then((res) => {
        commit("setPollDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addPoll({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/poll", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPoll({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/poll/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePoll({ dispatch }, data) {
    axios
      .delete("business/poll/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addPollQuestion({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/poll/question", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPollQuestion({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/poll/question/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePollQuestion({ dispatch }, data) {
    axios
      .delete("business/poll/question/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addPollOption({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/poll/option", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPollOption({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/poll/option/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePollOption({ dispatch }, data) {
    axios
      .delete("business/poll/option/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

// Transactions Actions ------------------------------------------------------------------
  getTransactions({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/transaction?branchPartId=" + data)
      .then((res) => {
        commit("setTransactions", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getTransaction({ dispatch, commit }, data) {
    axios
      .get("business/transaction/" + data)
      .then((res) => {
        commit("setTransaction", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addTransaction({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/transaction", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  addTransactionCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/customer", data.customer)
      .then((res) => {
        data.transaction.customerId = res.data.data;
        dispatch("addTransaction", data.transaction);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addTransactionsWithExcel({ dispatch }, data) {
    axios
    .post("business/transaction/import", data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
      dispatch('successPOSTHandler',
      {server: res,
       message: alert.success.addTransactions,
       redirect: false},
       {root: true})
    })
    .catch(error => {
      dispatch('errorPOSTHandler', error, {root:true});
    })
  },

  editTransaction({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/transaction/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteTransaction({ dispatch }, data) {
    axios
      .delete("business/transaction/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


// Dashboard Action ------------------------------------------------------------------
    getDashboardData({ dispatch, commit }, data) {
      commit(
        "setLoading",
        { status: 1, message: label.caption.loading },
        { root: true }
      );
      axios
        .get("business/dashboard?branchPartId=" + data)
        .then((res) => {
          commit("setDashboard", res.data.data);
        })
        .catch((error) => {
          dispatch("errorGETHandler", error, { root: true });
        })
        .finally(() => {
          setTimeout(() => {
            commit("setLoading", { status: 0, message: "" }, { root: true });
          }, 500);
        });
    },

};

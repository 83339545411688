import axios from "axios";
// import alert from "@/lang/AlertMessages.js";
// import label from "@/lang/StaticLabels.js";

export default {
  // Levels Actions ------------------------------------------------------------------
  getLevels({ dispatch, commit }) {
    axios
      .get("dropdown/level")
      .then((res) => {
        commit("setLevels", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Lebels Actions ------------------------------------------------------------------
  getLabels({ dispatch, commit }) {
    axios
      .get("dropdown/label")
      .then((res) => {
        commit("setLabels", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Province Actions ------------------------------------------------------------------
  getProvinces({ dispatch, commit }) {
    axios
      .get("dropdown/province")
      .then((res) => {
        commit("setProvinces", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  getCities({ dispatch, commit }, data) {
    axios
      .get("dropdown/city/" + data)
      .then((res) => {
        commit("setCities", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Banks Actions ------------------------------------------------------------------
  getBanks({ dispatch, commit }) {
    axios
      .get("dropdown/bank")
      .then((res) => {
        commit("setBanks", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // VendorCategory Actions ------------------------------------------------------------------
  getVendorCategory({ dispatch, commit }) {
    axios
      .get("dropdown/vendorCategory")
      .then((res) => {
        commit("setVendorCategory", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  getVendor({ dispatch, commit }, data) {
    axios
      .get("dropdown/vendor/" + data)
      .then((res) => {
        commit("setVendors", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

 // Customers Options ----------------------------------------------------------
   getCustomers({ dispatch, commit }) {
     axios
       .get("dropdown/customer")
       .then((res) => {
         commit("setCustomers", res.data.data);
       })
       .catch((error) => {
         dispatch("errorGETHandler", error, { root: true });
       });
   },

};
